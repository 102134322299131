import React, { useEffect } from 'react';
import { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { Dashboard } from './../Dashboard';
import { Portfolio } from './../Portfolio';
import { Analytics } from './../Analytics';
import { Diem, View } from './../Diem';
import { UserContext } from './../UserContext';
import { Foresight } from './../Foresight';
import { Enrichment } from './../Enrichment';
import { Benchmark } from './../Benchmark';
import { MyListContextProvider } from '../CardView/MyListContextProvider';
import { BookOfBusiness } from '../Dashboard/BookOfBusiness';
import { Hanover } from '../Hanover/Hanover';
import useGetRolesConfig from "../UserContextProvider/useGetRolesConfig";

const useStyles = makeStyles((theme) => ({
  screenContainer: {
    paddingBottom: theme.spacing(6),
  },
}));

const getFirstView = (user) => {
  let firstView;
  if (user.isOverallDiemEnabled()) {
    firstView = 'diem/overall';
  } else if (user.isScopedDiemEnabled()) {
    firstView = 'diem/team';
  } else if (user.isPersonalDiemEnabled()) {
    firstView = 'diem/personal';
  } else if (user.isOverallDashboardEnabled()) {
    firstView = 'book-of-business';
  } else if (user.isScopedOverallDashboardEnabled()) {
    firstView = 'team-book-of-business';
  } else if (user.isPersonalDashboardEnabled()) {
    firstView = 'my-business';
  } else if (user.isPortfolioEnabled()) {
    firstView = 'portfolio';
  } else if (user.isScopedAnalyticsEnabled()) {
    firstView = 'team-analytics';
  } else if (user.isPersonalAnalyticsEnabled()) {
    firstView = 'my-analytics';
  } else if (user.isAnalyticsEnabled()) {
    firstView = 'analytics';
  }
  return firstView;
};

export function Home() {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const isVizAccessAllowed = user.isVizAccessAllowed();
  let match = useRouteMatch();
  const firstView = getFirstView(user);

  const hasOverallDiem = user.isOverallDiemEnabled();
  const hasScopedDiem = user.isScopedDiemEnabled();
  const hasPersonalDiem = user.isPersonalDiemEnabled();
  const hasOverallDashboard = user.isOverallDashboardEnabled();
  const hasScopedOverallDashboard = user.isScopedOverallDashboardEnabled();
  const hasPortfolio = user.isPortfolioEnabled();
  const hasTeamAnalytics = user.isTeamAnalyticsEnabled();
  const hasPersonalAnalytics = user.isPersonalAnalyticsEnabled();
  const hasAnalytics = user.isAnalyticsEnabled();

  const isForecastAllowed = user.isForecastAllowed();
  const isEnrichmentDashboardAllowed = user.isEnrichmentDashboardAllowed();
  const isBenchmarkAllowed = user.isBenchmarkAllowed();
  const { data: rolesConfig } = useGetRolesConfig();
  let hanoverAIEnabled = false;
  if (rolesConfig) {
    const { hanoverAI } = rolesConfig;
    hanoverAIEnabled = hanoverAI;
  }
  const hanoverAI = user.getTenantApi().hasHanoverAI() && hanoverAIEnabled;

  useEffect(() => {
    const path = '/api/notification/v1/getAllNotifications';

    const checkFunc = async () => {
      const response = await fetch(path, {
        cache: 'no-store',
      });
      if (response.redirected) {
        window.location.href = response.url;
      }
    };
    if (isVizAccessAllowed) {
      checkFunc();
    }
  }, [isVizAccessAllowed]);

  return (
    <Switch>
      {firstView && (
        <Route exact path={match.path}>
          <Redirect to={`${match.path}/${firstView}`} />
        </Route>
      )}
      <Route path={match.path}>
        <MyListContextProvider>
          <Container className={classes.screenContainer} maxWidth="xl">
            <>
              {hasOverallDiem && (
                <Route path={`${match.path}/diem/overall`}>
                  <Diem type="overall" />
                </Route>
              )}
              {hasScopedDiem && (
                <Route path={`${match.path}/diem/team`}>
                  <Diem type="team" />
                </Route>
              )}
              {hasPersonalDiem && (
                <Route path={`${match.path}/diem/personal`}>
                  <Diem type="personal" />
                </Route>
              )}
              {hasOverallDiem && (
                <Route path={`${match.path}/view/overall`}>
                  <View type="diem/overall" />
                </Route>
              )}
              {hasScopedDiem && (
                <Route path={`${match.path}/view/team`}>
                  <View type="diem/team" />
                </Route>
              )}
              {hasPersonalDiem && (
                <Route path={`${match.path}/view/personal`}>
                  <View type="diem/personal" />
                </Route>
              )}
              {hasOverallDashboard && (
                <Route path={`${match.path}/book-of-business`}>
                  <BookOfBusiness />
                </Route>
              )}
              {hasScopedOverallDashboard && (
                <Route path={`${match.path}/team-book-of-business`}>
                  <Dashboard type="team-book-of-business" />
                </Route>
              )}
              {hasPortfolio && (
                <Route path={`${match.path}/portfolio`}>
                  <Portfolio />
                </Route>
              )}
              {hanoverAI && (
                <Route path={`${match.path}/hanover`}>
                  <Hanover />
                </Route>
              )}
              {hasAnalytics && (
                <Route path={`${match.path}/analytics`}>
                  <Analytics type="analytics" />
                </Route>
              )}
              {hasTeamAnalytics && (
                <Route path={`${match.path}/team-analytics`}>
                  <Analytics type="team-analytics" />
                </Route>
              )}
              {hasPersonalAnalytics && (
                <Route path={`${match.path}/my-analytics`}>
                  <Analytics type="my-analytics" />
                </Route>
              )}
              {isForecastAllowed && (
                <Route path={`${match.path}/foresight`}>
                  <Foresight />
                </Route>
              )}
              {isEnrichmentDashboardAllowed && (
                <Route path={`${match.path}/enrichment`}>
                  <Enrichment />
                </Route>
              )}
              {isBenchmarkAllowed && (
                <Route path={`${match.path}/benchmark`}>
                  <Benchmark />
                </Route>
              )}
            </>
          </Container>
        </MyListContextProvider>
      </Route>
    </Switch>
  );
}
