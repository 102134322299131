import axios from 'axios';
import { useEffect, useState } from 'react';
import { contextProperties } from './ContextProperties';

const getData = (customers) => {
  const data = [];
  if (customers) {
    const customerIdArr = Object.keys(customers);
    customerIdArr.forEach((customerId) => {
      try {
        const customer = customers[customerId];
        data.push(getCutomerStructure(customer, customerId));
      } catch (error) {
        return {
          error: true,
          errorMessage: 'Unable to parse state',
        };
      }
    });
  }
  return {
    error: false,
    data: data,
  };
};

const getCutomerStructure = (customer, customerId) => {
  const customerdDataStructure = {
    Main: customerId,
    Household: {
      [customerId]: {
        customerDetails: {
          CustomerId: customerId,
          'Customer ID.': customerId,
          Sentimeter: customer['KPI SENTIMETER Value'],
          KPISentimeterValue: customer['KPI SENTIMETER Value'],
          CsLastChangeDirection: customer['csLastChangeDirection'],
          IsCustomerMonoline: customer['DvCustomerIsMonoline'],
          DvCSR: customer['DvCsr'],
          DvCustomerMobilePhone: customer['DvCustomerMobilePhone'],
          'Kpi Sentimeter Value': customer['KPI SENTIMETER Value'],
          ...customer,
          DvCustomerName: getUpdatedCustomerName(customer),
          ['Customer Name']: getUpdatedCustomerName(customer),
        },
      },
    },
  };
  return customerdDataStructure;
};

const getUpdatedCustomerName = (customerDetails) => {
  try {
    if (customerDetails && Object.keys(customerDetails).length > 0) {
      const { DvCustomerName: name, Dvcustomername: fallbackName } =
        customerDetails;
      const customerName = customerDetails
        ? customerDetails?.['Customer Name']
        : '-';
      let dvcustomerName = '-';
      if (name) {
        dvcustomerName = name;
      } else if (fallbackName) {
        dvcustomerName = fallbackName;
      } else if (customerName) {
        dvcustomerName = customerName;
      }
      return dvcustomerName;
    }
    return '-';
  } catch (e) {
    return '-';
  }
};

function useAllMyListCustomers(context, load, stopLoad) {
  const [allCustomers, setAllCustomers] = useState({
    fetching: false,
    success: false,
    error: false,
    data: undefined,
  });
  const customerContext = contextProperties[context];
  const { customerSegmentPath: path } = customerContext;
  if (load === undefined) {
    load = true;
  }
  useEffect(() => {
    const getAllCustomers = async () => {
      setAllCustomers({
        fetching: true,
        success: false,
        error: false,
        data: undefined,
      });
      const requestObject = {};
      const headers = {
        'Content-Type': 'application/json',
      };
      const response = await fetch(path, {
        method: 'POST',
        headers,
        body: JSON.stringify(requestObject),
        cache: 'no-store',
      });
      if (response.redirected) {
        window.location.href = response.url;
      }
      const data = await response.json();
      const status = response.status;
      if (status !== 200 || data.error === true) {
        setAllCustomers({
          fetching: false,
          success: false,
          error: true,
          data: undefined,
        });
      } else {
        const filteredData = getData(data.data);
        if (filteredData.error) {
          setAllCustomers({
            fetching: false,
            success: false,
            error: true,
            data: undefined,
          });
        } else {
          setAllCustomers({
            fetching: false,
            success: true,
            error: false,
            data: filteredData.data,
          });
        }
      }
    };
    if (load) {
      if (typeof stopLoad === 'function') {
        stopLoad();
      }
      getAllCustomers();
    }
  }, [path, stopLoad, load]);
  return allCustomers;
}

export default useAllMyListCustomers;
