import { makeStyles } from "@material-ui/core";
import React from "react";
import SIAAFreePlanContract from "./SIAAFreePlanContract";
import splashScreenType from "../../Enums/splashScreenType";
import SIAAContractAccepted from "./SIAAContractAccepted";
import SIAAPaidPlanContract from "./SIAAPaidPlanContract";
import SIAAPlanUpgradeInProgress from "./SIAAPlanUpgradeInProgress";
import SIAAAMSContract from "./SIAAAMSContract";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 10,
    width: 700,
    height: 450,
    background: "white",
    display: "flex",
    margin: "auto",
    borderRadius: 10,
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  },
}));

const SIAAContract = (props) => {
  const { type } = props;
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {type === splashScreenType.FREE_PLAN && <SIAAFreePlanContract />}
      {type === splashScreenType.PAID_PLAN && <SIAAPaidPlanContract />}
      {type === splashScreenType.UPGRADE_IN_PROGRESS && (
        <SIAAPlanUpgradeInProgress />
      )}
      {type === splashScreenType.CONTRACT_ACCEPTED && <SIAAContractAccepted />}
      {type === splashScreenType.AMS_CONTRACT && <SIAAAMSContract />}
    </div>
  );
};

export default SIAAContract;
