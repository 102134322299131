import React, { useState } from 'react';
import { useContext } from 'react';
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UserContext } from './../UserContext';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { JiraDrawerItemWithDialog } from './../Jira';
import {
  HomeIcon,
  TutorialsAndVideosIcon,
  PortfolioIcon,
  BenchmarkingIcon,
  DataEnrichmentIcon,
  ForesightIcon,
  AnalyticsIcon,
  MetricsIcon,
  TrendsIcon,
  InsightsIcon,
  DashboardIcon,
  CustomersIcon,
  TeamsIcon,
  CarriersIcon,
  SettingsIcon,
  DefaultViewIcon,
  ListIcon,
  RenewIcon,
  RoundoutIcon,
  RetainIcon,
  MyViewsIcon,
  HanoverIcon,
} from '../Header/Icons';
import { LockMenu } from './LockMenu';
import {
  isEnrichmentDashboardAllowedForPlan,
  isTeamAnalyticsAllowedForPlan,
  isBenchmarkAllowedForPlan,
  isForecastAllowedForPlan,
  isCarrierAnalyticsAllowedForPlans,
  isInsightsAllowedForPlan,
  isTrendsAllowedForPlan,
  isMetricsAllowedForPlan,
  hideDisabled,
} from './../../utils/donnaPlansUtil';
import useGetRolesConfig from '../UserContextProvider/useGetRolesConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 190,
    backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  icon: {
    minWidth: 30,
  },
  expandedMenuItem: {
    background: '#DEE4E8',
    borderRadius: '0 0 5px 5px',
  },
  selected: {
    '&.Mui-selected': {
      background: '#DEE4E8',
      borderRadius: 5,
    },
  },
  selectedCollapse: {
    '&.Mui-selected': {
      background: '#DEE4E8',
      borderRadius: '5px 5px 0 0',
    },
  },
  selectedText: {
    '& > .MuiTypography-root': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  lockDiv: {
    backgroundColor: 'rgb(45,96,137,0.2)',
    width: 15,
    height: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    position: 'absolute',
    right: 0,
  },
}));

const OVERALL_DIEM_PATH = '/home/diem/overall';
const OVERALL_VIEW_PATH = '/home/view/overall';
const WELCOME_DONNA = '/home/welcome';
const SCOPED_DIEM_PATH = '/home/diem/team';
const PERSONAL_DIEM_PATH = '/home/diem/personal';
const SCOPED_VIEW_PATH = '/home/view/team';
const PERSONAL_VIEW_PATH = '/home/view/personal';
const OVERALL_DASHBOARD_PATH = '/home/book-of-business';
const SCOPED_DASHBOARD_PATH = '/home/team-book-of-business';
const PORTFOLIO_PATH = '/home/portfolio';
const ANALYTICS_PATH = '/home/analytics';
const SCOPED_ANALYTICS_PATH = '/home/team-analytics';
const PERSONAL_ANALYTICS_PATH = '/home/my-analytics';
const ANALYTICS_CUSTOMER = '/customer/all';
const ANALYTICS_TEAM = '/team';
const ANALYTICS_CARRIER = '/carrier';
const ANALYTICS_METRICS = '/metrics';
const ANALYTICS_TRENDS = '/trends';
const ANALYTICS_INSIGHTS = '/insights';
const ANALYTICS_CUSTOMER_PATH = `${ANALYTICS_PATH}${ANALYTICS_CUSTOMER}`;
const ANALYTICS_TEAM_PATH = `${ANALYTICS_PATH}${ANALYTICS_TEAM}`;
const ANALYTICS_CARRIERS_PATH = `${ANALYTICS_PATH}${ANALYTICS_CARRIER}`;
const ANALYTICS_METRICS_PATH = `${ANALYTICS_PATH}${ANALYTICS_METRICS}`;
const ANALYTICS_TRENDS_PATH = `${ANALYTICS_PATH}${ANALYTICS_TRENDS}`;
const ANALYTICS_INSIGHTS_PATH = `${ANALYTICS_PATH}${ANALYTICS_INSIGHTS}`;
const SCOPED_ANALYTICS_CUSTOMER_PATH = `${SCOPED_ANALYTICS_PATH}${ANALYTICS_CUSTOMER}`;
const SCOPED_ANALYTICS_TEAM_PATH = `${SCOPED_ANALYTICS_PATH}${ANALYTICS_TEAM}`;
const SCOPED_ANALYTICS_CARRIERS_PATH = `${SCOPED_ANALYTICS_PATH}${ANALYTICS_CARRIER}`;
const SCOPED_ANALYTICS_METRICS_PATH = `${SCOPED_ANALYTICS_PATH}${ANALYTICS_METRICS}`;
const SCOPED_ANALYTICS_TRENDS_PATH = `${SCOPED_ANALYTICS_PATH}${ANALYTICS_TRENDS}`;
const SCOPED_ANALYTICS_INSIGHTS_PATH = `${SCOPED_ANALYTICS_PATH}${ANALYTICS_INSIGHTS}`;
const PERSONAL_ANALYTICS_CUSTOMER_PATH = `${PERSONAL_ANALYTICS_PATH}${ANALYTICS_CUSTOMER}`;
const PERSONAL_ANALYTICS_TEAM_PATH = `${PERSONAL_ANALYTICS_PATH}${ANALYTICS_TEAM}`;
const PERSONAL_ANALYTICS_CARRIERS_PATH = `${PERSONAL_ANALYTICS_PATH}${ANALYTICS_CARRIER}`;
const PERSONAL_ANALYTICS_METRICS_PATH = `${PERSONAL_ANALYTICS_PATH}${ANALYTICS_METRICS}`;
const PERSONAL_ANALYTICS_TRENDS_PATH = `${PERSONAL_ANALYTICS_PATH}${ANALYTICS_TRENDS}`;
const PERSONAL_ANALYTICS_INSIGHTS_PATH = `${PERSONAL_ANALYTICS_PATH}${ANALYTICS_INSIGHTS}`;
const FORESIGHT_PATH = '/home/foresight';
const ENRICHMENT_PATH = '/home/enrichment';
const BENCHMARK_PATH = '/home/benchmark';
const HELP_VIDEOS_PATH = '/home/settings/help';
const SETTING_PATH = '/home/settings/app-settings';
const HANOVER_PATH = '/home/hanover';
const FEATURE_NOT_ALLOWED = '/home/settings/feature-not-available';

export function NestedList(props) {
  const { onClose: handleClose } = props;
  const classes = useStyles();
  const history = useHistory();
  const { pathname: pathName } = useLocation();
  const { user } = useContext(UserContext);
  const [analyticsOpen, setAnalyticsOpen] = useState(false);
  const [viewsOpen, setViewsOpen] = useState(false);

  const { data: rolesConfig } = useGetRolesConfig();
  let hanoverAIEnabled = false;
  if (rolesConfig) {
    const { hanoverAI } = rolesConfig;
    hanoverAIEnabled = hanoverAI;
  }

  const planType = user.getTenantApi().getPlanType();
  const hanoverAI = user.getTenantApi().hasHanoverAI() && hanoverAIEnabled;
  const isdataEnrichmentAllowed = isEnrichmentDashboardAllowedForPlan(planType);
  const isTeamAnalyticsAllowed = isTeamAnalyticsAllowedForPlan(planType);
  const isBenchMarkAllowedForPlan = isBenchmarkAllowedForPlan(planType);
  const isForesightAllowed = isForecastAllowedForPlan(planType);
  const isVizAccessAllowed = user.isVizAccessAllowed();
  const isInsightsAllowed = isInsightsAllowedForPlan(planType);
  const isCarrierAnalyticsAllowed = isCarrierAnalyticsAllowedForPlans(planType);
  const isTrendsAllowed = isTrendsAllowedForPlan(planType);
  const isMetricsAllowed = isMetricsAllowedForPlan(planType);
  const disabledHidden = hideDisabled(planType);

  const hasOverallDiem = user.isOverallDiemEnabled();
  const hasScopedDiem = user.isScopedDiemEnabled();
  const hasPersonalDiem = user.isPersonalDiemEnabled();
  const hasOverallDashboard = user.isOverallDashboardEnabled();
  const scopedDashboardTitle = hasOverallDashboard
    ? 'Team Dashboard'
    : 'Dashboard';
  const hasPortfolio = user.isPortfolioEnabled();
  const hasAnalytics = user.isAnalyticsEnabled();
  const hasScopedAnalytics = user.isScopedAnalyticsEnabled();
  const hasPersonalAnalytics = user.isPersonalAnalyticsEnabled();
  const hasCustomerAnalytics = user.isCustomerAnalyticsEnabled();
  const hasTeamAnalytics =
    user.isTeamAnalyticsEnabled() &&
    (!disabledHidden || isTeamAnalyticsAllowed);
  const hasCarrierAnalytics =
    user.isCarrierAnalyticsEnabled() &&
    (!disabledHidden || isCarrierAnalyticsAllowed);
  const hasMetricsAnalytics =
    user.isMetricsAnalyticsEnabled() && (!disabledHidden || isMetricsAllowed);
  const hasTrendsAnalytics =
    user.isTrendsAnalyticsEnabled() && (!disabledHidden || isTrendsAllowed);
  const hasInsightsAnalytics =
    user.isInsightsAnalyticsEnabled() && (!disabledHidden || isInsightsAllowed);
  const hasScopedOverallDashboard = user.isScopedOverallDashboardEnabled();
  const hasSettings = user.isSettingChangeAllowed() && !user.isDemoUser();
  const enableAnalytics =
    hasAnalytics || hasScopedAnalytics || hasPersonalAnalytics;
  let customerAnalyticsLink;
  let teamAnalyticsLink;
  let carrierAnalyticsLink;
  let metricsAnalyticsLink;
  let trendsAnalyticsLink;
  let insightsAnalyticsLink;
  if (hasPersonalAnalytics) {
    customerAnalyticsLink = PERSONAL_ANALYTICS_CUSTOMER_PATH;
    teamAnalyticsLink = PERSONAL_ANALYTICS_TEAM_PATH;
    carrierAnalyticsLink = PERSONAL_ANALYTICS_CARRIERS_PATH;
    metricsAnalyticsLink = PERSONAL_ANALYTICS_METRICS_PATH;
    trendsAnalyticsLink = PERSONAL_ANALYTICS_TRENDS_PATH;
    insightsAnalyticsLink = PERSONAL_ANALYTICS_INSIGHTS_PATH;
  } else if (hasScopedAnalytics) {
    customerAnalyticsLink = SCOPED_ANALYTICS_CUSTOMER_PATH;
    teamAnalyticsLink = SCOPED_ANALYTICS_TEAM_PATH;
    carrierAnalyticsLink = SCOPED_ANALYTICS_CARRIERS_PATH;
    metricsAnalyticsLink = SCOPED_ANALYTICS_METRICS_PATH;
    trendsAnalyticsLink = SCOPED_ANALYTICS_TRENDS_PATH;
    insightsAnalyticsLink = SCOPED_ANALYTICS_INSIGHTS_PATH;
  } else if (hasAnalytics) {
    customerAnalyticsLink = ANALYTICS_CUSTOMER_PATH;
    teamAnalyticsLink = ANALYTICS_TEAM_PATH;
    carrierAnalyticsLink = ANALYTICS_CARRIERS_PATH;
    metricsAnalyticsLink = ANALYTICS_METRICS_PATH;
    trendsAnalyticsLink = ANALYTICS_TRENDS_PATH;
    insightsAnalyticsLink = ANALYTICS_INSIGHTS_PATH;
  }

  let viewPath;
  if (hasOverallDiem) {
    viewPath = OVERALL_VIEW_PATH;
  } else if (hasScopedDiem) {
    viewPath = SCOPED_VIEW_PATH;
  } else {
    viewPath = PERSONAL_VIEW_PATH;
  }
  const isForecastAllowed =
    user.isForecastAllowed() && (!disabledHidden || isForesightAllowed);
  const isEnrichmentDashboardAllowed =
    user.isEnrichmentDashboardAllowed() &&
    (!disabledHidden || isdataEnrichmentAllowed);
  const isBenchmarkAllowed =
    user.isBenchmarkAllowed() && (!disabledHidden || isBenchMarkAllowedForPlan);
  const isAnalyticsPageSelected =
    matchPath(pathName, customerAnalyticsLink) ||
    matchPath(pathName, teamAnalyticsLink) ||
    matchPath(pathName, carrierAnalyticsLink) ||
    matchPath(pathName, metricsAnalyticsLink) ||
    matchPath(pathName, trendsAnalyticsLink) ||
    matchPath(pathName, insightsAnalyticsLink);
  const isAnalyticsSelected = analyticsOpen || isAnalyticsPageSelected;
  const isAnalyticsTextHighlighted = !analyticsOpen && isAnalyticsPageSelected;
  const isViewsSelected = viewsOpen;

  const handleAnalyticsClick = () => {
    setAnalyticsOpen(!analyticsOpen);
  };

  const handleViewsClick = () => {
    setViewsOpen(!viewsOpen);
  };

  const handleLink = (link, isAccessAllowed, destination) => {
    if (!isAccessAllowed && isAccessAllowed !== undefined) {
      history.push(`${FEATURE_NOT_ALLOWED}/${destination}`);
    } else {
      history.push(link);
    }
    handleClose();
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
        ></ListSubheader>
      }
      className={classes.root}
    >
      {!isVizAccessAllowed &&
        !hasOverallDiem &&
        !hasScopedDiem &&
        !hasPersonalDiem && (
          <ListItem
            button
            onClick={() => handleLink(WELCOME_DONNA)}
            selected={matchPath(pathName, WELCOME_DONNA)}
            classes={{ root: classes.selected }}
          >
            <ListItemIcon classes={{ root: classes.icon }}>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              primary="Home"
              className={clsx({
                [classes.selectedText]: matchPath(pathName, WELCOME_DONNA),
              })}
            />
          </ListItem>
        )}
      {hasOverallDiem && (
        <ListItem
          button
          onClick={() => handleLink(OVERALL_DIEM_PATH)}
          selected={matchPath(pathName, OVERALL_DIEM_PATH)}
          classes={{ root: classes.selected }}
        >
          <ListItemIcon classes={{ root: classes.icon }}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            primary="Home"
            className={clsx({
              [classes.selectedText]: matchPath(pathName, OVERALL_DIEM_PATH),
            })}
          />
        </ListItem>
      )}
      {hasScopedDiem && (
        <ListItem
          button
          onClick={() => handleLink(SCOPED_DIEM_PATH)}
          selected={matchPath(pathName, SCOPED_DIEM_PATH)}
          classes={{ root: classes.selected }}
        >
          <ListItemIcon classes={{ root: classes.icon }}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            primary="Home"
            className={clsx({
              [classes.selectedText]: matchPath(pathName, SCOPED_DIEM_PATH),
            })}
          />
        </ListItem>
      )}
      {hasPersonalDiem && (
        <ListItem
          button
          onClick={() => handleLink(PERSONAL_DIEM_PATH)}
          selected={matchPath(pathName, PERSONAL_DIEM_PATH)}
          classes={{ root: classes.selected }}
        >
          <ListItemIcon classes={{ root: classes.icon }}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            primary="Home"
            className={clsx({
              [classes.selectedText]: matchPath(pathName, PERSONAL_DIEM_PATH),
            })}
          />
        </ListItem>
      )}
      {hanoverAI && (
        <ListItem
          button
          onClick={() => handleLink(HANOVER_PATH)}
          selected={matchPath(pathName, HANOVER_PATH)}
          classes={{ root: classes.selected }}
        >
          <ListItemIcon classes={{ root: classes.icon }}>
            <HanoverIcon />
          </ListItemIcon>
          <ListItemText
            primary="Hanover AI"
            className={clsx({
              [classes.selectedText]: matchPath(pathName, HANOVER_PATH),
            })}
          />
        </ListItem>
      )}
      {hasOverallDashboard && (
        <ListItem
          button
          onClick={() => handleLink(OVERALL_DASHBOARD_PATH)}
          selected={matchPath(pathName, OVERALL_DASHBOARD_PATH)}
          classes={{ root: classes.selected }}
        >
          <ListItemIcon classes={{ root: classes.icon }}>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText
            primary="Dashboard"
            className={clsx({
              [classes.selectedText]: matchPath(
                pathName,
                OVERALL_DASHBOARD_PATH
              ),
            })}
          />
        </ListItem>
      )}
      {hasScopedOverallDashboard && (
        <ListItem
          button
          onClick={() => handleLink(SCOPED_DASHBOARD_PATH)}
          selected={matchPath(pathName, SCOPED_DASHBOARD_PATH)}
          classes={{ root: classes.selected }}
        >
          <ListItemIcon classes={{ root: classes.icon }}>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText
            primary={scopedDashboardTitle}
            className={clsx({
              [classes.selectedText]: matchPath(
                pathName,
                SCOPED_DASHBOARD_PATH
              ),
            })}
          />
        </ListItem>
      )}
      {hasPortfolio && (
        <ListItem
          button
          onClick={() => handleLink(PORTFOLIO_PATH)}
          selected={matchPath(pathName, PORTFOLIO_PATH)}
          classes={{ root: classes.selected }}
        >
          <ListItemIcon classes={{ root: classes.icon }}>
            <PortfolioIcon />
          </ListItemIcon>
          <ListItemText
            primary="My Portfolio"
            className={clsx({
              [classes.selectedText]: matchPath(pathName, PORTFOLIO_PATH),
            })}
          />
        </ListItem>
      )}

      <div>
        <ListItem
          button
          onClick={handleViewsClick}
          selected={isViewsSelected}
          classes={{ root: classes.selectedCollapse }}
        >
          <ListItemIcon classes={{ root: classes.icon }}>
            <MyViewsIcon />
          </ListItemIcon>
          <ListItemText primary="My Views" className={clsx({})} />
          {viewsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse
          in={viewsOpen}
          timeout="auto"
          unmountOnExit
          className={classes.expandedMenuItem}
        >
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              onClick={() => handleLink(`${viewPath}/default`)}
              selected={matchPath(pathName, `${viewPath}/default`)}
              classes={{ root: classes.selected }}
            >
              <ListItemIcon classes={{ root: classes.icon }}>
                <DefaultViewIcon />
              </ListItemIcon>
              <ListItemText
                primary="Default View"
                className={clsx({
                  [classes.selectedText]: matchPath(
                    pathName,
                    `${viewPath}/default`
                  ),
                })}
              />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => handleLink(`${viewPath}/viewAll/myList`)}
              selected={matchPath(pathName, `${viewPath}/viewAll/myList`)}
              classes={{ root: classes.selected }}
            >
              <ListItemIcon classes={{ root: classes.icon }}>
                <ListIcon />
              </ListItemIcon>
              <ListItemText
                primary="My List"
                className={clsx({
                  [classes.selectedText]: matchPath(
                    pathName,
                    `${viewPath}/viewAll/myList`
                  ),
                })}
              />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => handleLink(`${viewPath}/viewAll/RoundOut`)}
              selected={matchPath(pathName, `${viewPath}/viewAll/RoundOut`)}
              classes={{ root: classes.selected }}
            >
              <ListItemIcon classes={{ root: classes.icon }}>
                <RoundoutIcon />
              </ListItemIcon>
              <ListItemText
                primary="Round Out"
                className={clsx({
                  [classes.selectedText]: matchPath(
                    pathName,
                    `${viewPath}/viewAll/RoundOut`
                  ),
                })}
              />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => handleLink(`${viewPath}/viewAll/Retain`)}
              selected={matchPath(pathName, `${viewPath}/viewAll/Retain`)}
              classes={{ root: classes.selected }}
            >
              <ListItemIcon classes={{ root: classes.icon }}>
                <RetainIcon />
              </ListItemIcon>
              <ListItemText
                primary="Retain"
                className={clsx({
                  [classes.selectedText]: matchPath(
                    pathName,
                    `${viewPath}/viewAll/Retain`
                  ),
                })}
              />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => handleLink(`${viewPath}/viewAll/Renew`)}
              selected={matchPath(pathName, `${viewPath}/viewAll/Renew`)}
              classes={{ root: classes.selected }}
            >
              <ListItemIcon classes={{ root: classes.icon }}>
                <RenewIcon />
              </ListItemIcon>
              <ListItemText
                primary="Renew"
                className={clsx({
                  [classes.selectedText]: matchPath(
                    pathName,
                    `${viewPath}/viewAll/Renew`
                  ),
                })}
              />
            </ListItem>
          </List>
        </Collapse>
      </div>
      {enableAnalytics && (
        <>
          <ListItem
            button
            onClick={handleAnalyticsClick}
            selected={isAnalyticsSelected}
            classes={{ root: classes.selectedCollapse }}
          >
            <ListItemIcon classes={{ root: classes.icon }}>
              <AnalyticsIcon />
            </ListItemIcon>
            <ListItemText
              primary="Analytics"
              className={clsx({
                [classes.selectedText]: isAnalyticsTextHighlighted,
              })}
            />
            {!analyticsOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse
            in={!analyticsOpen}
            timeout="auto"
            unmountOnExit
            className={classes.expandedMenuItem}
          >
            <List component="div" disablePadding>
              {hasMetricsAnalytics && (
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() =>
                    handleLink(
                      metricsAnalyticsLink,
                      isMetricsAllowed,
                      'Metrics'
                    )
                  }
                  selected={matchPath(pathName, metricsAnalyticsLink)}
                  classes={{ root: classes.selected }}
                >
                  <ListItemIcon classes={{ root: classes.icon }}>
                    <MetricsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Metrics"
                    className={clsx({
                      [classes.selectedText]: matchPath(
                        pathName,
                        metricsAnalyticsLink
                      ),
                    })}
                  />
                  {!isMetricsAllowed && <LockMenu />}
                </ListItem>
              )}
              {hasTrendsAnalytics && (
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() =>
                    handleLink(trendsAnalyticsLink, isTrendsAllowed, 'Trends')
                  }
                  selected={matchPath(pathName, trendsAnalyticsLink)}
                  classes={{ root: classes.selected }}
                >
                  <ListItemIcon classes={{ root: classes.icon }}>
                    <TrendsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Trends"
                    className={clsx({
                      [classes.selectedText]: matchPath(
                        pathName,
                        trendsAnalyticsLink
                      ),
                    })}
                  />
                  {!isTrendsAllowed && <LockMenu />}
                </ListItem>
              )}
              {/* {hasInsightsAnalytics && (
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() =>
                    handleLink(
                      insightsAnalyticsLink,
                      isInsightsAllowed,
                      'Insights'
                    )
                  }
                  selected={matchPath(pathName, insightsAnalyticsLink)}
                  classes={{ root: classes.selected }}
                >
                  <ListItemIcon classes={{ root: classes.icon }}>
                    <InsightsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Insights"
                    className={clsx({
                      [classes.selectedText]: matchPath(
                        pathName,
                        insightsAnalyticsLink
                      ),
                    })}
                  />
                  {!isInsightsAllowed && <LockMenu />}
                </ListItem>
              )} */}
              {hasCustomerAnalytics && (
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => handleLink(customerAnalyticsLink)}
                  selected={matchPath(pathName, customerAnalyticsLink)}
                  classes={{ root: classes.selected }}
                >
                  <ListItemIcon classes={{ root: classes.icon }}>
                    <CustomersIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Customers"
                    className={clsx({
                      [classes.selectedText]: matchPath(
                        pathName,
                        customerAnalyticsLink
                      ),
                    })}
                  />
                </ListItem>
              )}
              {hasTeamAnalytics && (
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() =>
                    handleLink(
                      teamAnalyticsLink,
                      isTeamAnalyticsAllowed,
                      'Team Analytics'
                    )
                  }
                  selected={matchPath(pathName, teamAnalyticsLink)}
                  classes={{ root: classes.selected }}
                >
                  <ListItemIcon classes={{ root: classes.icon }}>
                    <TeamsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Team"
                    className={clsx({
                      [classes.selectedText]: matchPath(
                        pathName,
                        teamAnalyticsLink
                      ),
                    })}
                  />
                  {!isTeamAnalyticsAllowed && <LockMenu />}
                </ListItem>
              )}
              {hasCarrierAnalytics && (
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() =>
                    handleLink(
                      carrierAnalyticsLink,
                      isCarrierAnalyticsAllowed,
                      'Carrier Analytics'
                    )
                  }
                  selected={matchPath(pathName, carrierAnalyticsLink)}
                  classes={{ root: classes.selected }}
                >
                  <ListItemIcon classes={{ root: classes.icon }}>
                    <CarriersIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Carriers"
                    className={clsx({
                      [classes.selectedText]: matchPath(
                        pathName,
                        carrierAnalyticsLink
                      ),
                    })}
                  />
                  {!isCarrierAnalyticsAllowed && <LockMenu />}
                </ListItem>
              )}
            </List>
          </Collapse>
        </>
      )}
      {isEnrichmentDashboardAllowed && (
        <ListItem
          button
          onClick={() =>
            handleLink(
              ENRICHMENT_PATH,
              isdataEnrichmentAllowed,
              'Enrichment Dashboard'
            )
          }
          selected={matchPath(pathName, ENRICHMENT_PATH)}
          classes={{ root: classes.selected }}
        >
          <ListItemIcon classes={{ root: classes.icon }}>
            <DataEnrichmentIcon />
          </ListItemIcon>
          <ListItemText
            primary="Data Enrichment"
            className={clsx({
              [classes.selectedText]: matchPath(pathName, ENRICHMENT_PATH),
            })}
          />
          {!isdataEnrichmentAllowed && <LockMenu />}
        </ListItem>
      )}
      {isForecastAllowed && (
        <ListItem
          button
          onClick={() =>
            handleLink(FORESIGHT_PATH, isForesightAllowed, 'Foresight')
          }
          selected={matchPath(pathName, FORESIGHT_PATH)}
          classes={{ root: classes.selected }}
        >
          <ListItemIcon classes={{ root: classes.icon }}>
            <ForesightIcon />
          </ListItemIcon>
          <ListItemText
            primary="Foresight"
            className={clsx({
              [classes.selectedText]: matchPath(pathName, FORESIGHT_PATH),
            })}
          />
          {!isForesightAllowed && <LockMenu />}
        </ListItem>
      )}
      {isBenchmarkAllowed && (
        <ListItem
          button
          onClick={() =>
            handleLink(BENCHMARK_PATH, isBenchMarkAllowedForPlan, 'Benchmark')
          }
          selected={matchPath(pathName, BENCHMARK_PATH)}
          classes={{ root: classes.selected }}
        >
          <ListItemIcon classes={{ root: classes.icon }}>
            <BenchmarkingIcon />
          </ListItemIcon>
          <ListItemText
            primary="Benchmarking"
            className={clsx({
              [classes.selectedText]: matchPath(pathName, BENCHMARK_PATH),
            })}
          />
          {!isBenchMarkAllowedForPlan && <LockMenu />}
        </ListItem>
      )}
      {hasSettings && (
        <ListItem
          button
          onClick={() => handleLink(SETTING_PATH)}
          selected={matchPath(pathName, SETTING_PATH)}
          classes={{ root: classes.selected }}
        >
          <ListItemIcon classes={{ root: classes.icon }}>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText
            primary="Settings"
            className={clsx({
              [classes.selectedText]: matchPath(pathName, SETTING_PATH),
            })}
          />
        </ListItem>
      )}
      <ListItem
        button
        onClick={() => handleLink(HELP_VIDEOS_PATH)}
        selected={matchPath(pathName, HELP_VIDEOS_PATH)}
        classes={{ root: classes.selected }}
      >
        <ListItemIcon classes={{ root: classes.icon }}>
          <TutorialsAndVideosIcon />
        </ListItemIcon>
        <ListItemText
          primary="Tutorials & Videos"
          className={clsx({
            [classes.selectedText]: matchPath(pathName, HELP_VIDEOS_PATH),
          })}
        />
      </ListItem>
      <JiraDrawerItemWithDialog />
    </List>
  );
}

NestedList.propTypes = {
  onClose: PropTypes.func.isRequired,
};
