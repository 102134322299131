import { Button, makeStyles, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { UserContext } from "../UserContext";
import logo from "./../../assets/Donna_SIAA_Logo.png";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 10,
    width: "100%",
    height: "auto",
    padding: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    height: 80,
  },
  content: {
    margin: 10,
    textAlign: "center",
  },
  heading: {
    fontSize: 34,
    fontWeight: "bold",
    color: "#00669e",
  },
  subHeading: {
    fontSize: 28,
    margin: 10,
    marginTop: 20,
    marginBottom: 20,
    color: "#00669e",
  },
  agreementDetails: {
    margin: 10,
    fontSize: 18,
    textAlign: "center",
    lineHeight: 2,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10,
  },
  button: {
    color: "#195880",
    boxShadow: "none",
    backgroundColor: "#c3d3de",
  },
}));

const SIAAAMSContract = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div>
        <div className={classes.logoContainer}>
          <img className={classes.logo} src={logo} alt="Donna" />
        </div>
        <div className={classes.content}>
          <Typography className={classes.subHeading}>
            DONNA is getting ready for you!
          </Typography>
          <div className={classes.agreementDetails}>
            We're awaiting approval from the AMS provider,
            <br />
            and the process is taking a little more time.
            <br />
            We sincerely apologize for the delay and appreciate your patience.
          </div>
        </div>
      </div>
    </div>
  );
};

export default SIAAAMSContract;
