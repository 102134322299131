import { Button, makeStyles, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { UserContext } from "../UserContext";
import logo from "./../../assets/Donna_SIAA_Logo.png";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 10,
    width: "100%",
    height: "auto",
    padding: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    height: 80,
  },
  content: {
    margin: 10,
    textAlign: "center",
  },
  heading: {
    fontSize: 34,
    fontWeight: "bold",
    color: "#00669e",
  },
  subHeading: {
    fontSize: 28,
    margin: 10,
    color: "#00669e",
  },
  agreementDetails: {
    margin: 10,
    fontSize: 18,
    textAlign: "center",
    lineHeight: 2,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10,
  },
  button: {
    color: "#195880",
    boxShadow: "none",
    backgroundColor: "#c3d3de",
  },
}));

const SIAAPaidPlanContract = () => {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const userData = {
    email: user.getEmail(),
    userid: user.getTenantLevelUserId(),
    tenant: user.getTenant(),
  };
  const userDataStr = JSON.stringify(userData);
  const encodedUserData = Buffer.from(userDataStr).toString("base64");

  const hubspotURL = `${process.env.REACT_APP_SIAA_PAID_PLAN_AGREEMENT_URL}?etid=${encodedUserData}`;

  const faqURL = `${process.env.REACT_APP_DONNA_FAQ_URL}`;

  return (
    <div className={classes.container}>
      <div>
        <div className={classes.logoContainer}>
          <img className={classes.logo} src={logo} alt="Donna" />
        </div>
        <div className={classes.content}>
          <Typography className={classes.heading} style={{ fontWeight: 900 }}>
            Exciting News!
          </Typography>
          <Typography className={classes.subHeading}>
            DONNA is now part of SIAA
          </Typography>
          <div className={classes.agreementDetails}>
            Our Terms of Service have been updated.
            <br />
            To keep enjoying all your favourite DONNA features, please review
            and accept the updated terms.
          </div>
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button className={classes.button} href={faqURL} target="_blank">
          View FAQ
        </Button>
        <Button className={classes.button} href={hubspotURL}>
          Accept Terms
        </Button>
      </div>
    </div>
  );
};

export default SIAAPaidPlanContract;
