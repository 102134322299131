const roles = {
  SUPER_USER: {
    key: 'super-user',
    label: 'Super User',
    overallDashboard: true,
    scopedOverallDashboard: false,
    personalDashboard: false,
    portfolio: false,
    analytics: true,
    metricsAnalytics: true,
    trendsAnalytics: true,
    insightsAnalytics: true,
    customerAnalytics: true,
    teamAnalytics: true,
    carrierAnalytics: true,
    forecast: true,
    enrichmentDashboard: true,
    benchmark: true,
    scopedAnalytics: false,
    personalAnalytics: false,
    userManagement: true,
    settings: true,
    enabled: true,
    linkable: false,
    linkTarget: false,
    updateSource: false,
    updateTarget: false,
    vizAccess: true,
    assignable: false,
    covAccess: true,
    activityAccess: true,
    billing: true,
    dataFilter: false,
    nonAmsUser: false,
    overallDiem: true,
    scopedDiem: false,
    personalDiem: false,
  },
  ADMIN: {
    key: 'admin',
    label: 'Admin',
    overallDashboard: false,
    scopedOverallDashboard: false,
    personalDashboard: false,
    portfolio: false,
    analytics: false,
    metricsAnalytics: false,
    trendsAnalytics: false,
    insightsAnalytics: false,
    customerAnalytics: false,
    teamAnalytics: false,
    carrierAnalytics: false,
    forecast: false,
    enrichmentDashboard: false,
    benchmark: false,
    scopedAnalytics: false,
    personalAnalytics: false,
    userManagement: true,
    settings: true,
    enabled: true,
    linkable: true,
    linkTarget: false,
    updateSource: false,
    updateTarget: false,
    vizAccess: false,
    assignable: false,
    covAccess: false,
    activityAccess: false,
    billing: true,
    dataFilter: true,
    nonAmsUser: false,
    overallDiem: false,
    scopedDiem: false,
    personalDiem: false,
  },
  CEO: {
    key: 'ceo',
    label: 'CEO',
    overallDashboard: true,
    scopedOverallDashboard: false,
    personalDashboard: false,
    portfolio: true,
    analytics: true,
    metricsAnalytics: true,
    trendsAnalytics: true,
    insightsAnalytics: true,
    customerAnalytics: true,
    teamAnalytics: true,
    carrierAnalytics: true,
    forecast: true,
    enrichmentDashboard: true,
    benchmark: true,
    scopedAnalytics: false,
    personalAnalytics: false,
    userManagement: true,
    settings: true,
    enabled: true,
    linkable: false,
    linkTarget: true,
    updateSource: false,
    updateTarget: true,
    vizAccess: true,
    assignable: true,
    covAccess: true,
    activityAccess: true,
    billing: true,
    dataFilter: true,
    nonAmsUser: false,
    overallDiem: true,
    scopedDiem: false,
    personalDiem: false,
  },
  EXECUTIVE: {
    key: 'executive',
    label: 'Executive',
    overallDashboard: false,
    scopedOverallDashboard: false,
    personalDashboard: false,
    portfolio: true,
    analytics: false,
    metricsAnalytics: true,
    trendsAnalytics: true,
    insightsAnalytics: true,
    customerAnalytics: false,
    teamAnalytics: false,
    carrierAnalytics: false,
    forecast: false,
    enrichmentDashboard: false,
    benchmark: false,
    scopedAnalytics: true,
    personalAnalytics: false,
    userManagement: false,
    settings: false,
    enabled: true,
    linkable: false,
    linkTarget: false,
    updateSource: true,
    updateTarget: true,
    vizAccess: false,
    assignable: true,
    covAccess: true,
    activityAccess: true,
    billing: false,
    dataFilter: true,
    nonAmsUser: true,
    overallDiem: false,
    scopedDiem: true,
    personalDiem: false,
  },
  AGENT: {
    key: 'agent',
    label: 'Agent',
    overallDashboard: false,
    scopedOverallDashboard: false,
    personalDashboard: false,
    portfolio: true,
    analytics: false,
    metricsAnalytics: true,
    trendsAnalytics: true,
    insightsAnalytics: true,
    customerAnalytics: false,
    teamAnalytics: false,
    carrierAnalytics: false,
    forecast: false,
    enrichmentDashboard: false,
    benchmark: false,
    scopedAnalytics: false,
    personalAnalytics: true,
    userManagement: false,
    settings: false,
    enabled: true,
    linkable: false,
    linkTarget: false,
    updateSource: true,
    updateTarget: true,
    vizAccess: false,
    assignable: true,
    covAccess: true,
    activityAccess: true,
    billing: false,
    dataFilter: true,
    nonAmsUser: true,
    overallDiem: false,
    scopedDiem: false,
    personalDiem: true,
  },
  ADMIN_AGENT: {
    key: 'agent-admin',
    label: 'Agent Admin',
    overallDashboard: false,
    scopedOverallDashboard: false,
    personalDashboard: false,
    portfolio: true,
    analytics: false,
    metricsAnalytics: true,
    trendsAnalytics: true,
    insightsAnalytics: true,
    customerAnalytics: false,
    teamAnalytics: false,
    carrierAnalytics: false,
    forecast: false,
    enrichmentDashboard: false,
    benchmark: false,
    scopedAnalytics: false,
    personalAnalytics: true,
    userManagement: true,
    settings: false,
    enabled: false,
    linkable: false,
    linkTarget: true,
    updateSource: true,
    updateTarget: true,
    vizAccess: false,
    assignable: true,
    covAccess: true,
    activityAccess: true,
    billing: false,
    dataFilter: true,
    nonAmsUser: true,
    overallDiem: false,
    scopedDiem: false,
    personalDiem: true,
  },
};

const getEnabledRolesAsList = () => {
  const rolesList = [];
  Object.keys(roles).forEach((roleInternalKey) => {
    const roleDetails = roles[roleInternalKey];
    const { enabled } = roleDetails;
    if (enabled) {
      rolesList.push(roleDetails);
    }
  });
  return rolesList;
};

const getRoleDetails = (roleKey) => {
  return getEnabledRolesAsList().find(
    (roleDetails) => roleDetails.key === roleKey
  );
};

const isAnalyticsEnabledForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.analytics;
};

const isCustomerAnalyticsEnabledForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.customerAnalytics;
};

const isCarrierAnalyticsEnabledForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.carrierAnalytics;
};

const isTeamAnalyticsEnabledForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.teamAnalytics;
};

const isMetricsAnalyticsEnabledForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.metricsAnalytics;
};

const isTrendsAnalyticsEnabledForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.trendsAnalytics;
};

const isInsightsAnalyticsEnabledForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.insightsAnalytics;
};

const isForecastEnabledForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.forecast;
};

const isEnrichmentDashboardEnabledForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.enrichmentDashboard;
};

const isBenchmarkEnabledForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.benchmark;
};

const isScopedAnalyticsEnabledForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.scopedAnalytics;
};

const isPersonalAnalyticsEnabledForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.personalAnalytics;
};

const isOverallDiemEnabledForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.overallDiem;
};

const isScopedDiemEnabledForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.scopedDiem;
};

const isPersonalDiemEnabledForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.personalDiem;
};

const isUserManagementAllowedForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.userManagement;
};

const isLinkingAllowedForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.linkable;
};

const isSettingChangeAllowedForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.settings;
};

const isVizAccessAllowedForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.vizAccess;
};

const isCovAccessAllowedForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.covAccess;
};

const isActivityAccessAllowedForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.activityAccess;
};

const getLabelForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.label;
};

const getRoleLabelMapping = () => {
  const mapping = {};
  getEnabledRolesAsList().forEach((roleDetails) => {
    const { key, label } = roleDetails;
    mapping[key] = label;
  });
  return mapping;
};

const getAssignableRoleLabelMapping = () => {
  const mapping = {};
  getEnabledRolesAsList()
    .filter((roleDetails) => roleDetails.assignable)
    .forEach((roleDetails) => {
      const { key, label } = roleDetails;
      mapping[key] = label;
    });
  return mapping;
};

const getRoleKeys = () => {
  return getEnabledRolesAsList().map((roleDetails) => roleDetails.key);
};

const getAssignableRoleKeys = () => {
  return getEnabledRolesAsList()
    .filter((roleDetails) => roleDetails.assignable)
    .map((roleDetails) => roleDetails.key);
};

const getUpdateSourceRoleKeys = () => {
  return getEnabledRolesAsList()
    .filter((roleDetails) => roleDetails.updateSource)
    .map((roleDetails) => roleDetails.key);
};

const getUpdateTargetRoleKeys = () => {
  return getEnabledRolesAsList()
    .filter((roleDetails) => roleDetails.updateTarget)
    .map((roleDetails) => roleDetails.key);
};

const getLinkTargetRoleKeys = () => {
  return getEnabledRolesAsList()
    .filter((roleDetails) => roleDetails.assignable)
    .filter((roleDetails) => roleDetails.linkTarget)
    .map((roleDetails) => roleDetails.key);
};

const getNonAmsUserRoleKeys = () => {
  return getEnabledRolesAsList()
    .filter((roleDetails) => roleDetails.nonAmsUser)
    .map((roleDetails) => roleDetails.key);
};

const isRoleAssignable = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.assignable;
};

const isOverallDashboardAllowedForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.overallDashboard;
};

const isScopedOverallDashboardAllowedForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.scopedOverallDashboard;
};

const isPersonalDashboardAllowedForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.personalDashboard;
};

const isPortfolioAllowedForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.portfolio;
};

const isBillingAllowedForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.billing;
};

const isDataFilterAllowedForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.dataFilter;
};

const isNonAmsUserAllowedForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.nonAmsUser;
};

module.exports = {
  getRoleDetails,
  isAnalyticsEnabledForRole,
  isCustomerAnalyticsEnabledForRole,
  isTeamAnalyticsEnabledForRole,
  isCarrierAnalyticsEnabledForRole,
  isMetricsAnalyticsEnabledForRole,
  isTrendsAnalyticsEnabledForRole,
  isInsightsAnalyticsEnabledForRole,
  isForecastEnabledForRole,
  isEnrichmentDashboardEnabledForRole,
  isBenchmarkEnabledForRole,
  isScopedAnalyticsEnabledForRole,
  isPersonalAnalyticsEnabledForRole,
  isOverallDiemEnabledForRole,
  isScopedDiemEnabledForRole,
  isPersonalDiemEnabledForRole,
  isUserManagementAllowedForRole,
  isLinkingAllowedForRole,
  isSettingChangeAllowedForRole,
  isRoleAssignable,
  isOverallDashboardAllowedForRole,
  isScopedOverallDashboardAllowedForRole,
  isPersonalDashboardAllowedForRole,
  isPortfolioAllowedForRole,
  getLabelForRole,
  getRoleLabelMapping,
  getAssignableRoleLabelMapping,
  getRoleKeys,
  getLinkTargetRoleKeys,
  getNonAmsUserRoleKeys,
  isVizAccessAllowedForRole,
  isCovAccessAllowedForRole,
  isActivityAccessAllowedForRole,
  getAssignableRoleKeys,
  getUpdateSourceRoleKeys,
  getUpdateTargetRoleKeys,
  isBillingAllowedForRole,
  isDataFilterAllowedForRole,
  isNonAmsUserAllowedForRole,
};
