import { Button, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 10,
    width: "100%",
    height: 200,
    padding: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  title: {
    textAlign: "center",
    background: "#e3e3e3",
    padding: 10,
  },
  content: {
    margin: "auto",
    textAlign: "center",
    height: 150,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    color: "#195880",
    boxShadow: "none",
    backgroundColor: "#c3d3de",
  },
}));

const SIAAContractAccepted = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <div className={classes.content}>
          You have successfully accepted the agreement. Thank you for your
          co-operation!
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button className={classes.button} href="/">
          Continue using DONNA
        </Button>
      </div>
    </div>
  );
};

export default SIAAContractAccepted;
