import React from 'react';
import { useContext } from 'react';
import logo from './../../assets/donna.png';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTenantDetails } from './useTenantDetails';
import { UserContext } from './../UserContext';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
  },
  logo: {
    height: 50,
  },
  logoAlt: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  tenantText: {
    marginLeft: theme.spacing(2),
  },
}));

export function AppLogo() {
  const { user } = useContext(UserContext);
  const tenant = user.getTenant();
  const classes = useStyles();
  const { fetching, error, success, data: tenantDetails } = useTenantDetails();
  let agencyLogo;
  if (success) {
    ({ agencyLogo } = tenantDetails);
  }
  const showAgencyLogo = success && agencyLogo;
  const showDefaultLogo = (success && !agencyLogo) || error;

  let homePath = "/home";
  const OVERALL_DIEM_PATH = "/home/diem/overall";
  const SCOPED_DIEM_PATH = "/home/diem/team";
  const PERSONAL_DIEM_PATH = "/home/diem/personal";

  const hasOverallDiem = user.isOverallDiemEnabled();
  const hasScopedDiem = user.isScopedDiemEnabled();
  const hasPersonalDiem = user.isPersonalDiemEnabled();

  if (hasOverallDiem) {
    homePath = OVERALL_DIEM_PATH;
  } else if (hasScopedDiem) {
    homePath = SCOPED_DIEM_PATH;
  } else if (hasPersonalDiem) {
    homePath = PERSONAL_DIEM_PATH;
  }

  return (
    <div className={classes.root}>
      {showAgencyLogo && (
        <Link to={homePath}>
          <img className={classes.logo} src={agencyLogo} alt={tenant} />
        </Link>
      )}
      {fetching && <Skeleton variant="rect" height={41} width={160} />}
      {showDefaultLogo && (
        <div className={classes.logoAlt}>
          <Link to={homePath}>
            <img className={classes.logo} src={logo} alt="Donna" />
          </Link>
        </div>
      )}
    </div>
  );
}
